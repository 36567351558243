<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">处理物资采购申请</span>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label><b>物品名称</b></label>
            <el-input type="text" v-model="model.BODY.NAME" readonly/>
          </div>
          <div class="form-group">
            <label><b>备注</b></label>
            <el-input type="textarea" v-model="model.BODY.DESCRIPTION" :rows="2" readonly/>
          </div>
          <div class="form-group">
            <label><b>数量</b></label>
            <div>
              <el-input type="text" v-model="model.BODY.CNT" readonly />
            </div>
          </div>
          <div class="form-group">
            <label><b>金额</b></label>
            <div>
              <el-input type="text" v-model="model.BODY.AMOUNT" readonly />
            </div>
          </div>
          <div class="form-group">
            <label style="color: red"><b>处理意见</b></label>
            <el-input type="text" v-model="model.DESC"/>
          </div>
        </div>
      </div>
      <div slot="footer" v-loading="sta.loading">
        <el-button icon="el-icon-close" @click="sta.show=false">关闭</el-button>
        <el-button icon="el-icon-thumb" type="warning" @click="refuse">拒绝</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit">批准</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

export default {
  data(){
    return{
      sta:{
        show:false,
        loading:false
      },
      model: {BODY:{}},
      stock:{}
    }
  },
  methods:{
    init(model) {
      this.sta={show:true,loading:false}
      this.model =JSON.parse((JSON.stringify(model)));
      this.model.BODY=JSON.parse(this.model.BODY);
    },
    submit(){
      let self=this;
      self.sta.loading=true;
      this.whale.remote.getResult({
        url:"/api/School/WFL/Act/Next",
        data: {ID:this.model.ID},
        finally(){self.sta.loading=false;},
        completed:function (){
          self.sta.show=false;
          self.$emit("on-saved");
        }
      })
    },
    refuse(){
      let self=this;
      self.sta.loading=true;
      this.whale.remote.getResult({
        url:"/api/School/WFL/Act/Refuse",
        data: {ID:this.model.ID,DESC:this.model.DESC},
        finally(){self.sta.loading=false;},
        completed:function (){
          self.sta.show=false;
          self.$emit("on-saved");
        }
      })
    }
  }
}
</script>
